var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "CListGroup",
        {
          staticStyle: {
            "max-height": "400px",
            overflow: "auto",
            "border-radius": "0",
          },
        },
        _vm._l(_vm.agentNotes, function (agentNote, i) {
          return _c(
            "CListGroupItem",
            { key: i, attrs: { href: "#", color: "secondary" } },
            [
              _c("div", { domProps: { innerHTML: _vm._s(agentNote.content) } }),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center mt-1 justify-content-between agent-note-container",
                  staticStyle: { "border-top": "1px solid #b3b6b7" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex mt-2 agent-note-left-container" },
                    [
                      _c(
                        "div",
                        [
                          _c("h5", [_vm._v("İşlem")]),
                          _c(
                            "CBadge",
                            { attrs: { size: "lg", color: "success" } },
                            [
                              _vm._v(
                                _vm._s(agentNote.session_user.firstname) +
                                  " " +
                                  _vm._s(agentNote.session_user.lastname)
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "small" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.moment(agentNote.createdAt).fromNow()
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      agentNote?.files?.length
                        ? _c("div", { staticClass: "files-show" }, [
                            _c("h5", [_vm._v("Dosyalar")]),
                            _c(
                              "ul",
                              _vm._l(agentNote.files, function (file, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickAgentNoteFile(
                                          file.fileName
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(file.name) + " ")]
                                )
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("RoleProvider", {
                        attrs: { slug: "ADMIN_NOTE_UPDATE" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ canItPass }) {
                                return _c(
                                  "div",
                                  {},
                                  [
                                    canItPass
                                      ? _c(
                                          "CButton",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              size: "sm",
                                              color: "secondary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.agentNoteUpdate(
                                                  agentNote
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Düzenle")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("RoleProvider", {
                        attrs: { slug: "ADMIN_NOTE_DELETE" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ canItPass }) {
                                return _c(
                                  "div",
                                  {},
                                  [
                                    canItPass
                                      ? _c(
                                          "CButton",
                                          {
                                            attrs: {
                                              size: "sm",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.agentNoteDelete(
                                                  agentNote._id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Sil")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
      _c("CTextarea", {
        staticClass: "m-0 p-0 w-100",
        attrs: {
          rows: "6",
          placeholder:
            _vm.$i18n.locale == "tr"
              ? "Notunuzu buraya yazın..."
              : "Write your note here...",
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
      _c("div", { staticClass: "upload-file" }, [
        _c("input", {
          ref: "filesAgentNote",
          attrs: { type: "file", multiple: "" },
          domProps: { value: _vm.files },
          on: {
            change: function ($event) {
              return _vm.changeAgentFile()
            },
          },
        }),
      ]),
      _c("RoleProvider", {
        attrs: { slug: "ADMIN_NOTE_CREATE" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ canItPass }) {
              return _c(
                "div",
                {},
                [
                  canItPass
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.btnLoading,
                            block: "",
                            small: "",
                            color: "primary",
                            size: "lg",
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.btnLoading
                                ? "..."
                                : _vm.$i18n.locale == "tr"
                                ? "YENİ AGENT NOTU EKLE"
                                : "ADD NOTE"
                            ) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }