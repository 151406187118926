<template>
  <div class="w-100">
    <CListGroup style="max-height: 400px; overflow: auto; border-radius: 0">
      <CListGroupItem
        href="#"
        color="secondary"
        v-for="(agentNote, i) in agentNotes"
        :key="i"
      >
        <div v-html="agentNote.content"></div>
        <div
          class="d-flex align-items-center mt-1 justify-content-between agent-note-container"
          style="border-top: 1px solid #b3b6b7"
        >
          <div class="d-flex mt-2 agent-note-left-container">
            <div>
              <h5>İşlem</h5>

              <CBadge size="lg" color="success"
                >{{ agentNote.session_user.firstname }}
                {{ agentNote.session_user.lastname }}</CBadge
              >
              <div class="small">
                {{ moment(agentNote.createdAt).fromNow() }}
              </div>
            </div>
            <div class="files-show" v-if="agentNote?.files?.length">
              <h5>Dosyalar</h5>

              <ul>
                <li
                  v-for="(file, index) in agentNote.files"
                  :key="index"
                  @click="clickAgentNoteFile(file.fileName)"
                >
                  {{ file.name }}
                </li>
              </ul>
            </div>
          </div>

          <div class="d-flex">
            <RoleProvider slug="ADMIN_NOTE_UPDATE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="canItPass"
                  @click="agentNoteUpdate(agentNote)"
                  size="sm"
                  color="secondary"
                  class="mr-1"
                  >Düzenle</CButton
                >
              </div>
            </RoleProvider>
            <RoleProvider slug="ADMIN_NOTE_DELETE">
              <div slot-scope="{ canItPass }">
                <CButton
                  v-if="canItPass"
                  @click="agentNoteDelete(agentNote._id)"
                  size="sm"
                  color="primary"
                  >Sil</CButton
                >
              </div>
            </RoleProvider>
          </div>
        </div>
      </CListGroupItem>
    </CListGroup>
    <CTextarea
      rows="6"
      class="m-0 p-0 w-100"
      :placeholder="
        $i18n.locale == 'tr'
          ? 'Notunuzu buraya yazın...'
          : 'Write your note here...'
      "
      v-model="content"
    >
    </CTextarea>
    <div class="upload-file">
      <input
        v-on:change="changeAgentFile()"
        ref="filesAgentNote"
        type="file"
        multiple
        :value="files"
      />
    </div>

    <RoleProvider slug="ADMIN_NOTE_CREATE">
      <div slot-scope="{ canItPass }">
        <v-btn
          v-if="canItPass"
          :disabled="btnLoading"
          block
          small
          color="primary"
          size="lg"
          @click="submit"
          >{{
            btnLoading
              ? "..."
              : $i18n.locale == "tr"
              ? "YENİ AGENT NOTU EKLE"
              : "ADD NOTE"
          }}
        </v-btn>
      </div>
    </RoleProvider>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import ckeditor from "ckeditor4-vue";
import { RoleProvider } from "../provider";
var _ = require("lodash");

Vue.use(ckeditor);
Vue.use(VueSweetalert2);

export default {
  name: "AgentNote",
  props: {
    _id: {
      type: [String, Number],
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: false,
    },
    addClass: {
      type: String,
      default: "mb-2",
      required: false,
    },
  },
  components: { RoleProvider },
  data() {
    return {
      visibleLiveDemo: false,
      agentNotes: [],
      content: "",
      files: [],
      btnLoading: false,
    };
  },

  created() {
    this.getAgentNote(this._id);
  },
  methods: {
    changeAgentFile() {
      const _this = this;
      const files = this.$refs.filesAgentNote.files;

      const whiteListType = [
        "image/png",
        "image/jpeg",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/pdf",
      ];

      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        if (whiteListType.includes(element.type)) {
          let reader = new FileReader();

          reader.onload = function (e) {
            _this.files.push({
              base64: e.target.result,
              type: files[index].type,
              fileName: files[index].name,
            });
          };
          reader.readAsDataURL(element);
        } else {
          Vue.swal({
            icon: "error",
            title: "Dosya Ekle",
            html: "Yalnızca png, jpeg, pdf ve xlsx formatlı dosyalar yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
          _this.files = [];
          this.$refs.filesAgentNote.files = [];
        }
      }
    },
    clickAgentNoteFile(path) {
      window.location.href = process.env.VUE_APP_CDN_URL + "agentNote/" + path;
    },
    async submit() {
      this.btnLoading = true;
      const params = {
        content: this.content,
        place: {
          _id: this._id,
          slug: this.slug,
        },
        files: this.files,
      };

      try {
        let formData = new FormData();
        formData.append("content", this.content);
        formData.append(
          "place",
          JSON.stringify({
            _id: this._id,
            slug: this.slug,
          })
        );

        for (
          let index = 0;
          index < this.$refs.filesAgentNote.files.length;
          index++
        ) {
          const element = this.$refs.filesAgentNote.files[index];
          formData.append("files[" + index + "]", element);
        }
        await axios.post(process.env.VUE_APP_API_URL + "admin/note", formData, {
          withCredentials: true,
          headers: {
            "Content-Type": false,
          },
        });
        this.$store.dispatch({
          type: "successSwal",
          text: "Agent Not başarılı bir şekilde eklendi",
          refresh: false,
        });
        this.content = "";
        window.location.reload();
      } catch (error) {
        console.log(error);
        Vue.swal.fire({
          icon: "error",
          showCloseButton: true,
          text: error.response?.data?.message || "Bir sorun oluştu",
        });
      }
    },

    async getAgentNote(id) {
      try {
        let agentNotes = await axios.get(
          process.env.VUE_APP_API_URL + "admin/note?place._id=" + id,
          {
            withCredentials: true,
          }
        );
        this.agentNotes = _.reverse(agentNotes.data);
      } catch (error) {
        console.log(error);
      }
    },

    agentNoteDelete(params) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu Notu silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await axios.delete(
                process.env.VUE_APP_API_URL + "admin/note/" + params,
                { withCredentials: true }
              );

              this.$store.dispatch({
                type: "successSwal",
                text: "Agent Not başarılı bir şekilde silindi",
                refresh: false,
              });
              this.getAgentNote(this._id);
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message || "Bir sorun oluştu",
              });
            }
          }
        });
    },
    async agentNoteUpdate(params) {
      const { value: text } = await Vue.swal.fire({
        input: "textarea",

        inputValue: params.content,
        inputPlaceholder: "Type your message here...",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Güncelle",
        cancelButtonText: "Vazgeç",
      });

      if (text) {
        axios
          .put(
            process.env.VUE_APP_API_URL + "admin/note/" + params._id,
            { content: text },
            { withCredentials: true }
          )
          .then((res) => {
            this.$store.dispatch({
              type: "successSwal",
              text: "Agent Not başarılı bir şekilde düzenlendi",
              refresh: false,
            });
            this.getAgentNote(this._id);
          })
          .catch((error) => {
            Vue.swal.fire({
              icon: "error",
              showCloseButton: true,
              text: error.response.data.message || "Bir sorun oluştu",
            });
          });
      }
    },
  },
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .agent-note-container {
    flex-direction: column !important;
  }
  .agent-note-left-container {
    align-items: flex-start;
    flex-direction: column !important;
    width: 100%;
  }
  .files-show {
    border-left: none !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 1rem !important;
    border-top: 1px solid #b3b6b7;
    width: 100%;
    margin-bottom: 1rem !important;
  }
}

.files-show {
  margin-left: 3rem;
  padding-left: 3rem;
  border-left: 1px solid #b3b6b7;
}

.files-show ul li {
  padding: 0rem 1rem;
  color: #85929e !important;
  border: 1px solid #85929e;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  z-index: 1000;
}
.files-show ul li:hover {
  opacity: 0.7;
}
.upload-file {
  padding: 1rem 0.5rem;
}
</style>
